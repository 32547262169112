export const HOME_URL = "/";
export const APP_NAME = "SPCA-線上調查案件檢舉 Report Animal Abuse or Neglect";
export const API_URL = process.env.REACT_APP_API_URL;
export const UPLOAD_URL = process.env.REACT_APP_UPLOAD_URL;
export const ANNOUNCEMENT_URL = "/announcement";
export const IMG_PREFIX_URL = process.env.REACT_APP_IMG_PREFIX_URL;
export const DEFAULT_LANG = "zh_tw";
export const SPCA_OFFICIAL_SITE = "https://www.spca.org.tw/";
export const DEFAULT_PAGE = 0;
export const DEFAULT_SELECT_VALUE = "DEFAULT_SELECT_VALUE";
export const ROWS_PER_PAGE = 20;
export const TEL = "02-2738-2130";
export const EMAIL = "investigations@spca.org.tw";
